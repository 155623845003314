/**
 * Video formats
 */

import { debounce } from '../utils/debounce.js';

// DOM selectors
const $$video = document.getElementById('video');
const $$videoSourcesNodes = document.querySelectorAll('source');

// Handle video sources
const videoSources = {
	mobile: [
		{ src: 'videos/mobile.mp4', type: 'video/mp4' },
		{ src: 'videos/mobile.ogv', type: 'video/ogg' },
		{ src: 'videos/mobile.webm', type: 'video/webm' },
	],
	desktop: [
		{ src: 'videos/desktop.mp4', type: 'video/mp4' },
		{ src: 'videos/desktop.ogv', type: 'video/ogg' },
		{ src: 'videos/desktop.webm', type: 'video/webm' },
	],
};

let currentBreakpoint;

function checkSize() {
	if (window.matchMedia('(min-width: 768px)').matches && currentBreakpoint !== 'desktop') {
		currentBreakpoint = 'desktop'
		updateVideoSource('desktop');
		updateVideoPoster('desktop');
	} else if (window.matchMedia('(max-width: 767px)').matches && currentBreakpoint !== 'mobile') {
		currentBreakpoint = 'mobile'
		updateVideoSource('mobile');
		updateVideoPoster('mobile');
	}
}

function updateVideoSource(format) {
	$$videoSourcesNodes.forEach((node) => {
		// Set the node source equal to the src of the corresponding item in the videoSources object
		node.src = videoSources[format].find((item) => item.type === node.type).src;
	})
	$$video.load();
	$$video.play();
}

// Handle video posters
const videoPosters = {
	mobile: 'img/cover_mobile.jpg',
	desktop: 'img/cover_desktop.jpg',
};

function updateVideoPoster(format) {
	$$video.poster = videoPosters[format];
}

window.addEventListener('load', checkSize);
window.addEventListener('resize', debounce(checkSize, 500));
